import React, { Component } from "react"
import { Helmet } from "react-helmet"
import { StaticQuery, graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import Img from "gatsby-image"
import styled from "styled-components"
import ScrollTrigger from "react-scroll-trigger"
import scrollTo from "gatsby-plugin-smoothscroll"

import SectionFixedFullscreen from "../components/section-fixed-fullscreen"
import SectionFlexCenter from "../components/section-flex-center"
import SectionSolidBackground from "../components/section-solid-background"

import HamburgerButton from "../components/hamburger-button"
import HamburgerMenu from "../components/hamburger-menu"
import MainLogo from "../components/main-logo"
import Heading from "../components/heading"
import OurRestaurant from "../components/our-restaurant"
import Menu from "../components/menu"
import RestaurantInformation from "../components/restaurant-information"
import Text from "../components/text"
import Rectangle from "../components/rectangle"
import LSF from "../components/letter-spacing-fix"

import Portal from "../components/portal"
import Overlay from "../components/overlay"

const GreyText = styled.span`
    color: #939598;
`

const RectangleButtonHome = styled.a`
    font-family: 'Archivo', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    background: rgba(241, 234, 203, 0.8);
    color: #2A3038;
    line-height: 20px;
    letter-spacing: 0.3em;
    text-transform: uppercase;
    text-align: center;
    text-decoration: none;
    width: fit-content;
    padding: 15px 42px;
    margin-top: 38px;
    :hover {
        background: #F1EACB;
        cursor: pointer;
    }
    :active {
        background: #ABA485;
        color: #FFFFFF;
    }
    @media (max-width: 425px) {
        font-size: 14px;
    }
`

const RestaurantInformationImagesContainer = styled.div`
    width: 50%;
    display: flex;
    flex-direction: row;
    position: fixed;
    top: 0;
    right: 0;
    padding-top: 10vh;
    pointer-events: none;

    @media (max-width: 768px) {
        position: static;
        padding: 20px;
        width: auto;
    }
`

const RestaurantInformationImagesContainerMobile = styled.div`
    width: 50%;
    display: none;
    flex-direction: row;
    position: fixed;
    top: 0;
    right: 0;
    padding-top: 10vh;
    pointer-events: none;

    @media (max-width: 768px) {
        display: flex;
        position: static;
        padding: 20px;
        width: auto;
    }
`

const MenuImageContainerMobile = styled.div`
    display: none;
    // position: static;
    padding: 20px;
    // margin: 20px;
    // width: 100px;
    // height: 100px;

    @media (max-width: 768px) {
        display: block;
    }
`

const Reservation = styled.div`
    max-width: 674px;
    // width: 40%;
    padding: 100px 0 100px 15%;
    margin-right: 50%;
    text-align: left;

    @media (max-width: 768px) {
        // width: 50%;
        margin-right: 25%;
    }

    @media (max-width: 425px) {
        width: auto;
        padding: 100px 0;
        margin: 0 75px 0 20px;
    }
`

const PurchaseTicketLink = styled.a`
    // width: 100%;
    // height: 100%;
    text-decoration: none;
    color: #000;
    // margin: 15px;
    padding: 15px 30px;
    // line-height: 65px;
    display: block;
`

// Needed for bug in IE10 and IE11, see https://github.com/philipwalton/flexbugs#flexbug-3
const FlexContainerWrapper = styled.div`
    display: flex;
    flex-direction: column;
`

const resInfoImgStyle = {
    width: `10vw`,
    height: `10vw`,
    margin: `10px`
}

const resInfoImgStyleMobile = {
    width: `30vw`,
    height: `30vw`,
    margin: `10px`
}

const SectionFlexEnd = styled.div`
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    align-content: flex-end;
    z-index: 3;
    position: relative;
`

const FooterText = styled.div`
    color: #fff;
    font-family: 'Archivo Narrow', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 25px;
    letter-spacing: 0.15em;
    text-align: center;
    margin: 27px 20px 35px 20px;
    width: 60vw;
`

const FooterTextLight = styled.span`
    font-weight: normal;
`

const SocialIconsContainer = styled.div`
    position: fixed;
    display: flex;
    flex-direction: row;
    left: 10px;
    bottom: 10px;
    pointer-events: all;

    @media (max-width: 768px) {
        flex-direction: column;
    }

    div {
        margin: 10px;
    }
`

const InvisibleAcnhor = styled.a`
    display: none;
`

class HomePage extends Component {

    constructor(props) {
        super(props)
        this.state = {
            isMenuOpen: false,
            isScrolledPastIntro: false,
            showOverlay: false
        }
    }

    componentDidMount() {
        window.addEventListener('ft-widget-open', (e) => {
            this.setState({
                showOverlay: true,
                isMenuOpen: false
            })
        })
        window.addEventListener('ft-widget-close', (e) => {
            this.setState({
                showOverlay: false,
                isMenuOpen: false
            })
        })
    }

    toggleMenu() {
        this.setState({isMenuOpen: !this.state.isMenuOpen, showOverlay: !this.state.showOverlay})
    }

    closeAll() {
        this.setState({
            isMenuOpen: false,
            showOverlay: false
        })
        document.getElementById("invisible-anchor").click()
    }

    render() {
        return(
            <StaticQuery
                query={
                    graphql`
                        query {
                            backgroundImage: file(relativePath: { eq: "images/UNFO0626.jpg" }) {
                                childImageSharp {
                                    fluid {
                                        # ...GatsbyImageSharpFluid_tracedSVG
                                        ...GatsbyImageSharpFluid
                                    }
                                }
                            },
                            menuImage: file(relativePath: { eq: "images/UNFO0482.jpg" }) {
                                childImageSharp {
                                    fluid {
                                        ...GatsbyImageSharpFluid
                                    }
                                }
                            },
                            infoImage1: file(relativePath: { eq: "images/agriculture-animal-photography-animal-portrait-2863006.jpg" }) {
                                childImageSharp {
                                    fluid {
                                        ...GatsbyImageSharpFluid
                                    }
                                }
                            },
                            infoImage2: file(relativePath: { eq: "images/UNFO0477.jpg" }) {
                                childImageSharp {
                                    fluid {
                                        ...GatsbyImageSharpFluid
                                    }
                                }
                            },
                            infoImage3: file(relativePath: { eq: "images/UNFO0567.jpg" }) {
                                childImageSharp {
                                    fluid {
                                        ...GatsbyImageSharpFluid
                                    }
                                }
                            },
                            backgroundImageBottom: file(relativePath: { eq: "images/UNFO0515.jpg" }) {
                                childImageSharp {
                                    fluid {
                                        ...GatsbyImageSharpFluid
                                    }
                                }
                            },
                        }
                    `
                }
                render={data => (
                    <div>
                        <Helmet title="Restaurant Tres">
                            <html lang="en" />

                            {/* prevent scrolling when overlay is shown while menu or formitable widget is open */}
                            {/* <body className={this.state.showOverlay ? "lock-scrolling" : null} /> */}
                        </Helmet>

                        <SectionFixedFullscreen>
                            <BackgroundImage
                                Tag="section"
                                fluid={data.backgroundImage.childImageSharp.fluid}
                                style={{
                                    height: `100vh`,
                                }}
                                className="safari-background-flash-fix"
                            />
                        </SectionFixedFullscreen>
                        {this.state.isScrolledPastIntro ? 
                            <SectionFixedFullscreen>
                                <BackgroundImage
                                    Tag="section"
                                    fluid={data.backgroundImageBottom.childImageSharp.fluid}
                                    style={{
                                        height: `100vh`,
                                    }}
                                    className="safari-background-flash-fix"
                                />
                            </SectionFixedFullscreen>
                        : null }

                        <ScrollTrigger onEnter={() => this.setState({isScrolledPastIntro: false})}>
                            <FlexContainerWrapper>
                                <SectionFlexCenter direction="column">
                                    <MainLogo color="white" maxWidth="423px" />
                                    <RectangleButtonHome onClick={() => scrollTo('#reservation')}>Reservation</RectangleButtonHome>
                                </SectionFlexCenter>
                            </FlexContainerWrapper>
                        </ScrollTrigger>

                        <ScrollTrigger onEnter={() => this.setState({isScrolledPastIntro: false})}>
                            <SectionSolidBackground backgroundColor="white" id="our_restaurant" minHeight="100vh">
                                <FlexContainerWrapper>
                                    <SectionFlexCenter>
                                        <OurRestaurant id="our-restaurant" />
                                    </SectionFlexCenter>
                                </FlexContainerWrapper>
                            </SectionSolidBackground>
                        </ScrollTrigger>

                        <SectionSolidBackground backgroundColor="white" minHeight="auto">
                            <MenuImageContainerMobile>
                                <Img 
                                    fluid={data.menuImage.childImageSharp.fluid}
                                    style={{
                                        width: `80vw`,
                                        maxWidth: `350px`,
                                        height: `auto`,
                                        margin: `0 auto`,
                                        // padding: `20px`,
                                        // position: `static`,
                                    }}
                                    className=""
                                />
                            </MenuImageContainerMobile>
                        </SectionSolidBackground>

                        <SectionSolidBackground backgroundColor="white" id="menu_"  minHeight="100vh">
                            <FlexContainerWrapper>
                                <SectionFlexCenter>
                                    <Menu id="menu" />
                                </SectionFlexCenter>
                            </FlexContainerWrapper>
                        </SectionSolidBackground>

                        <SectionSolidBackground backgroundColor="white" minHeight="auto">
                            <RestaurantInformationImagesContainerMobile id="" className="">
                                <Img
                                    fluid={data.infoImage1.childImageSharp.fluid}
                                    style={ resInfoImgStyleMobile }
                                />
                                <Img
                                    fluid={data.infoImage2.childImageSharp.fluid}
                                    style={ resInfoImgStyleMobile }
                                />
                                <Img
                                    fluid={data.infoImage3.childImageSharp.fluid}
                                    style={ resInfoImgStyleMobile }
                                />
                            </RestaurantInformationImagesContainerMobile>
                        </SectionSolidBackground>


                        <SectionSolidBackground backgroundColor="white" id="info_"  minHeight="100vh">
                            <FlexContainerWrapper>
                                <SectionFlexCenter>
                                    <RestaurantInformation id="restaurant-information" />
                                </SectionFlexCenter>
                            </FlexContainerWrapper>
                        </SectionSolidBackground>

                        <InvisibleAcnhor id="invisible-anchor" href="#ft-close">hide</InvisibleAcnhor>

                        <ScrollTrigger onEnter={() => this.setState({isScrolledPastIntro: true})}>
                            <SectionSolidBackground backgroundColor="white" id="reservation"  minHeight="100vh">
                                <FlexContainerWrapper>
                                    <SectionFlexCenter>
                                        <Reservation>
                                            <Heading>Reservatio<LSF>n</LSF></Heading>  
                                            <Text>
                                                We work with a deposit for the cost of the menu. On the reserved afternoon or evening, guests are able to choose from our wine and beverage list or pairings, and settle the rest of the menu costs and drinks at the end of the experience.
                                            </Text>
                                            <Text>
                                                Please note, we cannot accommodate all types of allergies or dietary restrictions. Nevertheless do inform upon reserving.
                                            </Text>
                                            <Rectangle>
                                                <PurchaseTicketLink target="_blank" href="https://apiq-apiv1-c01.apiq.sfdc-lywfpd.svc.sfdcfc.net/r?target=67c74e1247581351c51fb9e5&t=V2_eyJwX2l2IjoiMklmMHJTNGdwdHhpNlRWYzQ1V3ZMUVx1MDAzZFx1MDAzZCIsInBfZW5jcnlwdGVkX2RhdGEiOiIzYmFQTDhNWFZSSHZIUEVwaEpjRTRaNG5xVEQxbmc0Tkc4OUJFM0Yzc1hjXHUwMDNkIiwicF9vcmQiOiIxIiwicF9pZCI6IjQifXx7InBfaXYiOiJpVXZyd3FTYlZQVk9HRHVXc0JuTDhRXHUwMDNkXHUwMDNkIiwicF9lbmNyeXB0ZWRfZGF0YSI6ImZrdEM2RzFwY2JkRTJ0TlRPMmVzZ2FENnUxY0VXYWRCYk9yUlNzbldWeFJRUlZ2MzZvRjcrRGRYOHJMZUNjZkZlU1dFb0V4Nm1MZUR1dW5TbVBQUWRnXHUwMDNkXHUwMDNkIiwicF9vcmQiOiIxIiwicF9pZCI6IjExMTk5MiJ9&url=https%3A%2F%2Fwww.exploretock.com%2Ftresrotterdam">Purchase ticket</PurchaseTicketLink>
                                            </Rectangle>
                                            <Text>
                                                <GreyText>
                                                    Cancellation Policy: Tickets are fully interchangeable and guests are free to cancel their reservation up to 48 hours prior to their meal. After that time, we will do what we can to find another guest to take over the ticket, but if we can't, no refunds will be issued.
                                                </GreyText>
                                            </Text>
                                        </Reservation>
                                    </SectionFlexCenter>
                                </FlexContainerWrapper>
                            </SectionSolidBackground>
                        </ScrollTrigger>

                        <ScrollTrigger onEnter={() => this.setState({isScrolledPastIntro: true})}>
                            <FlexContainerWrapper>
                                <SectionFlexEnd>
                                    <MainLogo color="white" maxWidth="292px" />
                                    <FooterText>
                                    <FooterTextLight>Photography:</FooterTextLight> Unfolded - Sophia van den Hoek | <FooterTextLight>Web Design:</FooterTextLight> Denise Nijdam | <FooterTextLight>Web Development:</FooterTextLight> Ian Rieken | Tres 2024 ©
                                    </FooterText>
                                </SectionFlexEnd>
                            </FlexContainerWrapper>
                        </ScrollTrigger>

                        <Portal>
                            <Img 
                                fluid={data.menuImage.childImageSharp.fluid}
                                style={{
                                    width: `20vw`,
                                    height: `60vh`,
                                    margin: `20vh 0 20vh 15vw`,
                                    position: `fixed`,
                                    top: 0,
                                    left: 0,
                                    pointerEvents: `none`,
                                    zIndex: 3
                                }}
                                className="menu-image image-to-hide"
                            />
                        </Portal>
                        <Portal>
                            <RestaurantInformationImagesContainer id="resImgContainer" className="image-to-hide">
                                <Img
                                    fluid={data.infoImage1.childImageSharp.fluid}
                                    style={ resInfoImgStyle }
                                />
                                <Img
                                    fluid={data.infoImage2.childImageSharp.fluid}
                                    style={ resInfoImgStyle }
                                />
                                <Img
                                    fluid={data.infoImage3.childImageSharp.fluid}
                                    style={ resInfoImgStyle }
                                />
                            </RestaurantInformationImagesContainer>
                        </Portal>
                        <Portal>
                                <SocialIconsContainer>
                                    {/* FaceBook */}
                                    <div>
                                        <a href="https://www.facebook.com/Tres-Rotterdam-105617157462568/" target="blank">
                                            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M30 15C30 6.71637 23.2836 0 15 0C6.71637 0 0 6.71637 0 15C0 22.4863 5.48451 28.6921 12.6565 29.8187V19.337H8.84685V15H12.6565V11.6945C12.6565 7.93553 14.8967 5.85776 18.3223 5.85776C19.9634 5.85776 21.6805 6.15096 21.6805 6.15096V9.84248H19.7883C17.9258 9.84248 17.3435 10.9984 17.3435 12.186V14.9999H21.5032L20.8387 19.3369H17.3434V29.8186C24.5154 28.6941 30 22.4883 30 15Z" fill="#C7C8CA" fill-opacity="0.5"/>
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M20.8389 19.3369L21.5033 14.9999H17.3437V12.186C17.3437 11.0005 17.9237 9.84251 19.7884 9.84251H21.6806V6.15099C21.6806 6.15099 19.9636 5.85779 18.3224 5.85779C14.8968 5.85779 12.6566 7.93348 12.6566 11.6946V15H8.84698V19.337H12.6566V29.8187C13.4202 29.9388 14.2028 30 15.0001 30C15.7975 30 16.5801 29.9367 17.3437 29.8187V19.337H20.839L20.8389 19.3369Z" fill="#FEFEFE"/>
                                            </svg>
                                        </a>
                                    </div>
                                    {/* Instagram */}
                                    <div>
                                        <a href="https://www.instagram.com/tresrotterdam/" target="blank">
                                            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M15 6.66669C10.3984 6.66669 6.66669 10.3984 6.66669 15C6.66669 19.6017 10.3984 23.3334 15 23.3334C19.6017 23.3334 23.3334 19.6017 23.3334 15C23.3334 10.3984 19.6017 6.66669 15 6.66669ZM15 20C12.2386 20 10 17.7615 10 15C10 12.2386 12.2386 10 15 10C17.7615 10 20 12.2386 20 15C20 17.7615 17.7615 20 15 20Z" fill="white"/>
                                                <path d="M25 6.66665C25.9205 6.66665 26.6666 5.92045 26.6666 4.99998C26.6666 4.07951 25.9205 3.33331 25 3.33331C24.0795 3.33331 23.3333 4.07951 23.3333 4.99998C23.3333 5.92045 24.0795 6.66665 25 6.66665Z" fill="white"/>
                                                <path d="M15 20C17.7614 20 20 17.7614 20 15C20 12.2386 17.7614 10 15 10C12.2386 10 10 12.2386 10 15C10 17.7614 12.2386 20 15 20Z" fill="#C7C8CA" fill-opacity="0.5"/>
                                                <path d="M21.6667 0H8.33333C3.73167 0 0 3.73167 0 8.33333V21.6667C0 26.2683 3.73167 30 8.33333 30H21.6667C26.2683 30 30 26.2683 30 21.6667V8.33333C30 3.73167 26.2683 0 21.6667 0ZM15 23.3333C10.3983 23.3333 6.66667 19.6017 6.66667 15C6.66667 10.3983 10.3983 6.66667 15 6.66667C19.6017 6.66667 23.3333 10.3983 23.3333 15C23.3333 19.6017 19.6017 23.3333 15 23.3333ZM25 6.66667C24.08 6.66667 23.3333 5.92 23.3333 5C23.3333 4.08 24.08 3.33333 25 3.33333C25.92 3.33333 26.6667 4.08 26.6667 5C26.6667 5.92 25.92 6.66667 25 6.66667Z" fill="#C7C8CA" fill-opacity="0.5"/>
                                            </svg>
                                        </a>
                                    </div>
                                </SocialIconsContainer>
                        </Portal>

                        {/* Overlay */}
                        { this.state.showOverlay ? <Portal><Overlay onClick={() => this.closeAll()}/></Portal> : null }

                        {/* Hamburger button */}
                        { this.state.isMenuOpen ? null : <Portal><HamburgerButton onClick={() => this.toggleMenu()} /></Portal>}

                        {/* Hamburger menu */}
                        { this.state.isMenuOpen ? <Portal><HamburgerMenu onItemClick={() => this.toggleMenu()} /></Portal> : null }
                    </div>
                )}
            />
        )
    }
}

export default HomePage